<template>
    <div class="footer">
        <div style="text-align:center">&copy; 2022 北京乐米科技有限公司 <a href="http://beian.miit.gov.cn/">京ICP备12018775号</a></div>
    </div>
</template>

<style scoped>
.footer {
    font-size: .75rem;
    width: calc(100% - 12px);
    overflow: hidden;
    padding:0;
    margin: 30px auto;
}
</style>