<template>
  <div class="main">
    <Swiper :slides="slides" />

    <div class="wrapper">
      <div class="mt-8 mb-8">
        <div class="grid">
          <div class="col">
            <img src="@/assets/team.webp" />
          </div>
          <div class="col text-left mt-8">
            <h1>回天人才</h1>
            <h3>回龙观和天通苑，中国最大的工程师聚集地</h3>
            <p>这里汇聚了各方面专业技术人才，方便项目快速组建团队</p>
          </div>
        </div>
      </div>
      <div class="mt-8 mb-8">
        <div class="grid">
          <div class="col text-center mt-8">
            <h1>企业服务</h1>
            <h3>企业SCRM, 企业微信开发服</h3>
            <p>私域流量运营，数据分析，智能洞察</p>
          </div>
          <div class="col">
            <img src="@/assets/pad.webp" />
          </div>
        </div>
      </div>
      <div class="space"></div>
      <div class="mt-8 mb-8">
        <div class="grid">
          <div class="col">
            <img src="@/assets/apps.webp" />
          </div>
          <div class="col text-left mt-8">
            <h1>移动APP开发</h1>
            <p style="width:460px">移动应用软件ECM专注开发中大型移动APP，我们可以为客户开发基于苹果、安卓的APP应用，根据客户的不同需求开发框架型原生型（native app）和轻量级APP。</p>
          </div>
        </div>
      </div>
      <div class="mt-8 mb-8">
        <div class="grid">
          <div class="col text-center">
            <h1>全渠道平台管理系统</h1>
            <div class="rada">
                <img src="@/assets/rada.webp" />
                <div class="r1">平台</div>
                <div class="r2">管理</div>
                <div class="r3">策略</div>
                <div class="r4">研发</div>
                <div class="r5">人才</div>
            </div>
          </div>
        </div>
      </div>      
    </div>
  </div>
</template>

<script setup>
import { reactive } from "@vue/reactivity";
import back1 from "@/assets/back1.jpg";
import back2 from "@/assets/back2.jpg";
import Swiper from "@/components/Swiper.vue";

const slides = reactive([
  {
    src: back1,
    post: [
      {
        text: "网络协同项目研发管理",
        style: {
          fontSize: "3rem",
          fontWeight: "600",
          color: "#fefefe",
        },
      },
      {
        text: "汇聚各方人才，灵活用工",
        style: {
          fontSize: "1.5rem",
          color: "#fefefe",
          margin: "20px 0",
        },
      },
      {
        text: "创新的一站式项目开发服务平台,帮助企业提高项目管理效率",
        style: {
          fontSize: "1.3rem",
          color: "#fefefe",
          margin: "20px 0",
        },
      },
    ],
    postStyle: {
      top: "40%",
      left: "60%",
      textAlign: "left",
    },
  },
  {
    src: back2,
    post: [
      {
        text: "智能效率跟踪系统",
        style: {
          fontSize: "3rem",
          fontWeight: "600",
          color: "#fefefe",
        },
      },
      {
        text: "需求变更，迭代进度，测试验收",
        style: {
          fontSize: "1.5rem",
          color: "#fefefe",
          margin: "20px 0",
        },
      },
      {
        text: "不再有说不清的变化，绩效清晰才是质量保证",
        style: {
          fontSize: "1.3rem",
          color: "#fefefe",
          margin: "20px 0",
        },
      },
    ],
    postStyle: {
      top: "30%",
      left: "10%",
      textAlign: "left",
    },
  },
]);
</script>

<style scoped>
.wrapper {
  background: url(~@/assets/back0.jpg);
  background-color: #000000;
}
.space{
    display: block;
    height: 80px;
}
.rada {
    position: relative;
    width:597px;
    height:486px;
    margin: 20px auto;
}
.rada .r1 {
    position: absolute;
    top: 80px;
    left: 580px;
    width: 200px;
    text-align: left;
}
.rada .r2 {
    position: absolute;
    top: 312px;
    left: 600px;
    width: 200px;
    text-align: left;    
}
.rada .r3 {
    position: absolute;
    top: 460px;
    left: 420px;
    width: 200px;
    text-align: left;        
}
.rada .r4 {
    position: absolute;
    top: 370px;
    left: -170px;
    width: 200px;
    text-align: right;      
}
.rada .r5 {
    position: absolute;
    top: 132px;
    left: -205px;
    width: 200px;
    text-align: right;   
}

</style>