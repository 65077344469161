<template>
  <swiper class="swiper" :modules="[EffectCube,Pagination,Navigation]" effect="fade" 
    :pagination="{ clickable: true }" :loop="true" :navigation="true">
      <template v-for="(item, index) in slides" :key="index">
          <swiper-slide>
              <div class="slide" :style="styleOf(item)">
                  <div class="text" v-if="item.post" :style="item.postStyle">
                      <div v-for="(t, i) in item.post" :key="i" :style="t.style">{{t.text}}</div>
                  </div>
              </div>
          </swiper-slide>
      </template>
  </swiper>
</template>

<script setup>
import { EffectCube } from 'swiper';
// eslint-disable-next-line
import { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { defineProps } from 'vue'
import 'swiper/swiper-bundle';
import 'swiper/swiper-bundle.min.css';

defineProps({
    slides: Array
})

function styleOf(item){
    var background = 'url('+item.src+')'
    return { background }
}

</script>

<style lang="scss">
@import 'swiper/swiper.scss';
.swiper {
    width: 100%;
    overflow: hidden;
}
.slide {
  width: 100%;
  min-height: 600px;
  overflow: hidden;
  padding: 0;
  position: relative;
}
.slide > .text {
    position: absolute;
}

</style>